
import React from "react";

import Button from 'react-bootstrap/Button';

import Form from 'react-bootstrap/Form';




  
class AddCategoryForm extends React.Component {
  
 

  handleSubmit = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    let catName = form.name.value;
    
    let url =   process.env.REACT_APP_JSONSTORES_URL+'/bookmark/'+ this.props.userKey

        var myObj = {list: [{op:"add", path:"/bookmarkList/0", "value": {name: catName, list:[]}}]};
   
        var json = JSON.stringify(myObj);
        var xhr = new XMLHttpRequest()
        xhr.addEventListener('load', () => {
          
        })
        xhr.open('PATCH', url,false)
        xhr.send(json)
  
  };



  render(){

    return(
        <div>
            <Form onSubmit={this.handleSubmit}>
                      <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control  name="name" />
                       
                      </Form.Group>

                      <Button variant="primary" type="submit" style={{float: 'right'}}>
                        Submit
                      </Button>
            </Form>
        </div>
    )

    

  }



}


export default AddCategoryForm;