
import About from './About.js';
import Main from './Main.js';
import LoginCallback from './LoginCallback';
import React from 'react';
import Home from './Home.js'
import { BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';


  
  class App extends React.Component {
    render() {
      return (
        <BrowserRouter>
        <div>
          
          <Switch>
          <Route path="/" component={Home} exact/>
          <Route path="/index.html" component={Home} exact/>
          <Route path="/about.html" component={About}/>
          <Route path="/main.html" component={Main}/>
          <Route path="/login-callback.html" component={LoginCallback}/>
          <Route path="*">
            <ErrorPage />
          </Route>
          </Switch>
        </div>
        </BrowserRouter>
      );
    }
  }

  function ErrorPage(){
    return <div class="row d-flex justify-content-center mt-5">
           
            <div class="col-md-6 text-center">
              <div class="card shadow rounded" >
                
                <div class="card-body">
                  <h5 class="card-title"><h1><strong>404</strong></h1></h5>
                  <p class="card-text"><h5>The page you requested was not found.</h5></p>
                  <a href="/index.html" class="btn btn-primary">GO TO HOME PAGE</a>
                </div>
              </div>
            </div>
           
          </div>
  
  }
  
  export default App;