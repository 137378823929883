import React from "react";

import "./App.css";
import Button from 'react-bootstrap/Button';



  
class Home extends React.Component {
  
    onClickLogin = (e) => {
        let   loginUrl = process.env.REACT_APP_JSONSTORES_URL+'/login.htm'
        window.open(loginUrl,"_self")
    }
    
  
 
  render() {

    
   
    return (
<div>
          
          
        <div
			class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3  border-bottom box-shadow">
			<h5 class="my-0 mr-md-auto font-weight-normal"><a class="p-2 " href="/index.html">SimplyBookmark.com</a></h5>
			<nav class="my-2 my-md-0 mr-md-3">

					<Button variant="outline-success" className="float-right" onClick={this.onClickLogin}>Login</Button>

			</nav>

		</div>

		
		<div class="container">
			<div class="row">
				<div class="col">
				
				</div>
				<div class="col-8">
					
					<br></br>
					<h2 class="">the most simplest Bookmarking tools ever!</h2>
					<img src="./img/screenshot.png" class="img-thumbnail"  alt="Lights" width={650} height={300} mode='fit' ></img>
					
					<div class="max-auto text-center">
						
						
					</div>

					<br></br>
					<br></br>
					

					<h2 class="">It's free and are working everywhere!</h2>
					<p>No need to install any extensions, apps or software. No fancy features, just simply works online.
					</p>

					
					<br></br>
					<br></br>


					<h2 class="">Organized by category</h2>
					<p>Organized by category, keep all related URL in one place.

					</p>
				</div>
				<div class="col">
				
				</div>
			</div>
		</div>


	
	
	
	<footer class="footer pt-4 my-md-5 pt-md-5 ">
		<div class="row">
			<div class="col-6 col-md text-center">
				
				<small class="d-block mb-3 text-muted">Made by @zhaoboly  &copy; 2020-2021</small>
			</div>
		</div>
	</footer>

</div>
    );
  }
}

export default Home;
