import React from "react";

import "./App.css"



  
class About extends React.Component {
  
 
  
 
  render() {

   
    return (
        <div>
          
          
            <h2>Hello world</h2>
        </div>
    );
  }
}

export default About;
