import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
  
export const getValidUrl = (url = "") => {
  //let newUrl = window.decodeURIComponent(url);
  let newUrl= url;
  newUrl = newUrl.trim().replace(/\s/g, "");

  if(/^(:\/\/)/.test(newUrl)){
      return `http${newUrl}`;
  }
  if(!/^(f|ht)tps?:\/\//i.test(newUrl)){
      return `http://${newUrl}`;
  }

  return newUrl;
};


class AddBookmarkForm extends React.Component {


  handleSubmit = event => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        let bookmarkName = form.name.value ; 
        var bookmarkUrl = getValidUrl(form.url.value);
        let bookmarkCategory = form.category.value;


        var xhr = new XMLHttpRequest()

        var myObj = {list: [{op:"add", path:"/bookmarkList/"+bookmarkCategory+"/list/0", "value": { name : bookmarkName, url : bookmarkUrl}}]};


        xhr.addEventListener('load', () => {
         
          //console.log(xhr.responseText)

        })

        var url = process.env.REACT_APP_JSONSTORES_URL +'/bookmark/'+ this.props.userKey
        xhr.open('PATCH', url,false)

        xhr.send(JSON.stringify(myObj))
    };



  render(){

    return(
        <div>
            <Form onSubmit={this.handleSubmit}>
                      <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control  name="name" />
                       
                      </Form.Group>
                      <Form.Group controlId="url">
                        <Form.Label>URL</Form.Label>
                        <Form.Control  name="url" />
                       
                      </Form.Group>
                      <Form.Group controlId="category">
                        <Form.Label>Category</Form.Label>
                        <Form.Control as="select" defaultValue={this.props.selectedCategoryKey}>
                            {this.props.bookmarkList && this.props.bookmarkList.map((item, index) => {
                            return <option value={index} key={index}>{item.name}</option>
                            })}
                        
                        
                        </Form.Control>
                    </Form.Group>
                    
            
                      <Button variant="primary" type="submit" style={{float: 'right'}}>
                        Submit
                      </Button>
            </Form>
        </div>
    )

    

  }



}


export default AddBookmarkForm;