import React from "react";

import "../App.css"



class SettingPage extends React.Component {
  


  componentDidMount() {
    
  }

  render() {

    return (
          <div style={{color:'gray'}}>          
       
            <h3>SETTING</h3>
            <hr></hr>
            



            <h3>User Key : </h3>
            {this.props.userKey}
            <br></br>
            <hr></hr>          

        </div>

    );
  }
}

export default SettingPage;
