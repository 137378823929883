import React from "react";


// this class will received userKey from the server login callback and save it in localStorage
// then call the main page
class LoginCallback extends React.Component {
  

   
componentDidMount() {

    let curUrl = window.location.href;
    if(curUrl.includes("userKey=")){
      let index = curUrl.indexOf("userKey=");
      this.userKey = curUrl.substr(index+8)
  
      //alert("userKey:"+ this.userKey)
      console.log("userKey:"+ this.userKey)
  
      localStorage.setItem('userKey', this.userKey);

      window.open("/main.html","_self")
    }
}


  render() {
   
   
    return (
        <div>
          
          <noscript>You need to enable JavaScript to run this app.</noscript>

        </div>
    );
  }
}

export default LoginCallback;
