import React from "react";

import "../App.css"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class BookmarkRow extends React.Component {
  

  data = [];

  componentDidMount() {
    this.data = this.props.data;
  }
  
  onClickCategoryDelete = (name, uuid) => event => {
    alert("are you sure to delete this category:"+ name)
    
}

  onClickClose= (index, categoryId) =>event => {

        var xhr = new XMLHttpRequest()

        var myObj = {list: [{op:"remove", path:"/bookmarkList/"+this.props.categoryIndex+"/list/"+index, "value": ""}]};

        xhr.addEventListener('load', () => {
          
          this.data.splice(index,1);
          
          this.forceUpdate();
         
        })

        let url =   process.env.REACT_APP_JSONSTORES_URL+'/bookmark/'+ this.props.userKey

        xhr.open('PATCH', url,false)

        xhr.send(JSON.stringify(myObj))

  };

 
  render() {

    return (
            
                  <Row>

                      {this.data && this.data.map((link,index) => {

                          
                          return <Col xs={6} md={3} key={index}>
                          <Toast style ={{ marginTop: '0.8rem' }}   onClose={this.onClickClose(index, this.props.uuid)} >
                              <Toast.Header style={{backgroundColor:'lightgreen'}}>
                                
                              <strong className="mr-auto"><div ><a target="_blank" rel="noopener noreferrer"  href={link.url}>{link.name.substring(0,20)}&nbsp;</a></div></strong>
                              
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">
                                                                <div>{link.name}</div>
                                                                <div>{link.url}</div>
                                

                                                          </Tooltip>}>
                                      <span className="d-inline-block">
                                      <i className="fas fa-eye"></i>
                                      </span>
                                </OverlayTrigger>
                              </Toast.Header>
                              <Toast.Body >
                                            <a target="_blank" rel="noopener noreferrer"  href={link.url}>
                                            
                                            <div style={{whiteSpace: 'nowrap'}}>{link.url}</div>
                                            </a>
                              </Toast.Body>
                            </Toast>
                            
                            </Col>
                            
                            
                      })}

                  </Row>

         
    );
  }
}

export default BookmarkRow;
