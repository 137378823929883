import React from "react";

import "./css/base.css";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AddCategoryForm from './components/AddCategoryForm.js';
import AddBookmarkForm from './components/AddBookmarkForm.js';

import BookmarkRow from './components/BookmarkRow.js';
import SettingPage from './components/SettingPage.js';
import { AiOutlineSetting } from 'react-icons/ai';

class Main extends React.Component {
  
  /*
  constructor(props) {
    super(props);
    
  }*/

  state = {
    openAddCategoryModal: false,
    openAddBookmarkModal: false,
    selectedCategoryKey: 1
 };
   
 bookmarkList;

  
  
  defaultActiveKey ;

  
  userKey ;
   
componentDidMount() {

    this.checkUserKeyAndLoadPage()
    window.accessKey = window.getMessage(process.env.REACT_APP_JSONSTORES_ID)
}



checkUserKeyAndLoadPage(){

  this.userKey = localStorage.getItem('userKey')
 
  if(this.userKey){
      console.log("userKey:"+ this.userKey)
      this.loadPageByUserKey()
  }else{
    let curUrl = window.location.href;
    if(curUrl.includes("userKey=")){
      let index = curUrl.indexOf("userKey=");
      this.userKey = curUrl.substr(index+8)
  
      //alert("userKey:"+ this.userKey)
      console.log("userKey:"+ this.userKey)
  
      localStorage.setItem('userKey', this.userKey);
  
      this.loadPageByUserKey()
    }
  }

}

loadPageByUserKey(){
  
    let xhr = new XMLHttpRequest()
    xhr.addEventListener('load', () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        let response = JSON.parse(xhr.responseText)
        this.bookmarkList = response.bookmarkList;
        
        this.forceUpdate()
      }else if (xhr.readyState === 4 && xhr.status === 404) {
        //it's the first to get bookmark, create an empty one
        this.createFirstBookmarkProduct();
      }
    })
    let url =   process.env.REACT_APP_JSONSTORES_URL+'/bookmark/' + this.userKey     
    xhr.open('GET',  url ,false)
    xhr.send();
}    

createFirstBookmarkProduct(){
  let xhr = new XMLHttpRequest()
  xhr.addEventListener('load', () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      let response = JSON.parse(xhr.responseText)
      this.bookmarkList = response.bookmarkList;
      this.forceUpdate()
    }else {
      alert("Failed to retrieve data from the server.")
    
    }
  })
  let url =   process.env.REACT_APP_JSONSTORES_URL+'/bookmark/' + this.userKey  
  xhr.open('POST',  url ,false)
  xhr.send(JSON.stringify({bookmarkList: []}));
}

 


onClickAddCategoryModal = (e) => {
  if(this.userKey){
    this.setState({ openAddCategoryModal:true});
  }else{
    alert("please login first.")
  }
}

onClickAddBookmarkModal = (e) => {
  if(this.userKey){
    this.setState({ openAddBookmarkModal:true});
  }else{
    alert("please login first.")
  }
}


onClickLogin = (e) => {
  let loginUrl = process.env.REACT_APP_JSONSTORES_URL+'/login.htm'

  window.open(loginUrl,"_self")
}

onClickLogout = (e) => {
  localStorage.removeItem("userKey")
 
  
  this.userKey = null
  this.forceUpdate()

  window.open("https://simplybookmark.com", "_self")
}

 //for test purpose, 
onTestButtonClick= (e) =>{

  localStorage.removeItem("userKey")
  return;
 }

 onTabPaneExit=(e)=>{
   
   //alert(e.target);
 }

 
  render() {
   
    
    let settingButton = <Button style={{marginRight:10}} variant="outline-success" className="float-right" onClick={()=>this.setState({selectedCategoryKey: "settingKey"})}><AiOutlineSetting /></Button>
    
    let userButton;
    if (this.userKey) {
      userButton = <Button variant="outline-success" className="float-right" onClick={this.onClickLogout}>Logout</Button>
                
    } else {
      userButton = <Button variant="outline-success" className="float-right" onClick={this.onClickLogin}>Login</Button>
    }

   
    return (
        <div>
          
          
        <Tab.Container id="left-tabs-example"  activeKey={this.state.selectedCategoryKey} onSelect={(key)=> this.setState({selectedCategoryKey: key})}>
           
            <p></p>
            <p></p>
            <Row style ={{ marginRight: '0.8rem' }}>
              <Col sm={3} ><div>&nbsp;&nbsp;&nbsp;<Button variant="secondary" onClick={this.onClickAddCategoryModal}>Add Category</Button>
                </div></Col>
              <Col sm={9}>
                <div>
                
                <Button variant="secondary" onClick={this.onClickAddBookmarkModal}>Add Bookmark</Button>

                
                {userButton}
                {settingButton}
               
                {/* <Button onClick={this.onTestButtonClick}>Test</Button>*/}
                
                </div>

              </Col>
              
            </Row>

            <p></p>
            <hr></hr>
            <p></p>

            <Row style ={{ marginRight: '0.8rem' }} >
            
            
              <Col sm={3}  >
                
                <Nav  className="flex-column" >

               

                {this.bookmarkList && this.bookmarkList.map((item,index) => {
                    return <Nav.Item key={index}> 
                              <Nav.Link eventKey={index}><strong>{item.name}</strong></Nav.Link>
                        </Nav.Item>
                 })}
                
               
                </Nav>
              </Col>
              <Col sm={9}> 
               
                <Tab.Content>
                    
                    {this.bookmarkList && this.bookmarkList.map((item,index) => {
                        return   <Tab.Pane eventKey={index} key={index} onEntered={this.onTabPaneExit}> 
                        
                                  <BookmarkRow userKey={this.userKey} categoryIndex={index} data={item.list}/>
                            </Tab.Pane>
                    })}

                    <Tab.Pane eventKey='settingKey' key='9999'>
                        <SettingPage userKey={this.userKey} ></SettingPage>
                    </Tab.Pane>

                </Tab.Content>
                
               
              </Col>
            </Row>
          </Tab.Container>

  

          <Modal show={this.state.openAddCategoryModal} onHide={() => this.setState({ openAddCategoryModal:false})}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddCategoryForm userKey={this.userKey}/>
                </Modal.Body>
          </Modal>
             
          <Modal show={this.state.openAddBookmarkModal} onHide={() => this.setState({ openAddBookmarkModal:false})}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Bookmark</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddBookmarkForm userKey={this.userKey} bookmarkList={this.bookmarkList}  selectedCategoryKey={this.state.selectedCategoryKey}/>
                </Modal.Body>
          </Modal>

          
        </div>
    );
  }
}

export default Main;
